import {useNavigation} from '@remix-run/react'
import clsx from 'clsx'
import {useEffect, useRef, useState} from 'react'

export const LoadingProgressBar = () => {
  const transition = useNavigation()
  const isBusy = transition.state !== 'idle'
  const ref = useRef<HTMLDivElement>(null)

  const [isAnimationComplete, setIsAnimationComplete] = useState(true)

  useEffect(() => {
    if (!ref.current) return
    if (isBusy) {
      setIsAnimationComplete(false)
    }

    const animationPromises = ref.current
      .getAnimations()
      .map((animation) => animation.finished)

    Promise.allSettled(animationPromises).then(() => {
      if (!isBusy) {
        setIsAnimationComplete(true)
      }
    })
  }, [isBusy])

  return (
    <div
      role="progressbar"
      aria-hidden={isBusy ? undefined : true}
      aria-valuetext={isBusy ? 'Loading...' : undefined}
      className="fixed top-0 left-0 w-full h-[3px] z-50"
    >
      <div
        ref={ref}
        className={clsx(
          'h-full w-0 bg-primary-900 duration-[660ms] ease-[cubic-bezier(0.65, 0, 0.35, 1)] transition-all',
          transition.state == 'idle' &&
            (isAnimationComplete ? 'transition-none' : 'w-full'),
          transition.state == 'submitting' && 'w-4/12',
          transition.state == 'loading' && 'w-8/12',
        )}
      />
    </div>
  )
}
